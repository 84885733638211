import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {TemplatePortalDirective} from '@angular/cdk/portal';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';

@Component({
  selector: 'app-custom-dropdown',
  template: `
    <ng-template cdk-portal="">
      <ng-content></ng-content>
    </ng-template>`,
  styleUrls: ['./custom-dropdown.component.scss']
})
export class CustomDropdownComponent implements OnInit {

  constructor(protected overlay: Overlay) {
  }
  @Input()
  public reference: HTMLElement;

  @ViewChild(TemplatePortalDirective)
  public contentTemplate: TemplatePortalDirective;

  protected overlayRef: OverlayRef;

  public showing = false;

  ngOnInit(): void {
  }

  public show(): void {
    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    this.overlayRef.attach(this.contentTemplate);
    // this.syncWidth();
    this.overlayRef.backdropClick().subscribe(() => this.hide());
    this.showing = true;
  }

  public hide(): void {
    this.overlayRef.detach();
    this.showing = false;
  }

  @HostListener('window:resize')
  public onWinResize(): void {
    // this.syncWidth();
  }

  /**
   * If we want to sync on resize / make it the same size as the select
   */
  private syncWidth(): void {
    if (!this.overlayRef) {
      return;
    }

    const refRect = this.reference.getBoundingClientRect();
    this.overlayRef.updateSize({width: refRect.width});
  }

  protected getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(this.reference)
      .withPush(false)
      .withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top'
      }, {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom'
      }]);

    return new OverlayConfig({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop'
    });
  }
}
