<form [formGroup]="form" fxLayout="column" fxLayoutAlign="center center" class="px20px pt20px" fxFill>
  <span fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutAlign.sm="start center"
    fxLayoutAlign.xs="start center" fxFlex="100" fxFill [fxLayoutGap]="getFlexValue('gap')">
    <span fxLayout="row wrap" fxLayoutGap="7px">

      <app-custom-select [fxFlex]="getFlexValue('alter')" class="custom-select f-formfield-label clickable"
        [placeholder]="'searchList.selectors.alter' | translate" [showSelected]="false" [selected]="alter"
        translatePrefix="searchList.selectors.ageOptions." [formControlName]="'alter'">
        <app-custom-select-option *ngFor="let o of AgeOptions" [key]="o"
          [value]="'searchList.selectors.ageOptions.' + o | translate">
        </app-custom-select-option>
      </app-custom-select>

      <app-custom-select [fxFlex]="getFlexValue('src')" class="custom-select f-formfield-label clickable"
        [placeholder]="'searchList.selectors.src' | translate" [showSelected]="false" [selected]="src" [srcMode]="true"
        translatePrefix="searchList.selectors.channelOptions." [formControlName]="'src'">
        <span class="clickable f-preview-overline select-titles" (click)="selectAll('streaming')">{{'Streaming'}}</span>
        <app-custom-select-option *ngFor="let o of ChannelOptions.streaming" [key]="o" [group]="'streaming'"
          [value]="'searchList.selectors.channelOptions.' + o | translate">
        </app-custom-select-option>
        <span class="clickable f-preview-overline select-titles" (click)="selectAll('tv')">{{'TV'}}</span>
        <app-custom-select-option *ngFor="let o of ChannelOptions.tv" [key]="o" [group]="'tv'"
          [value]="'searchList.selectors.channelOptions.' + o | translate">
        </app-custom-select-option>
        <span class="clickable f-preview-overline select-titles" (click)="selectAll('kino')">{{'Kino'}}</span>
        <app-custom-select-option *ngFor="let o of ChannelOptions.kino" [key]="o" [group]="'kino'"
          [value]="'searchList.selectors.channelOptions.' + o | translate">
        </app-custom-select-option>
        <span class="clickable f-preview-overline select-titles" (click)="selectAll('kino')">{{'Social Media'}}</span>
        <app-custom-select-option *ngFor="let o of ChannelOptions.social" [key]="o" [group]="'social'"
          [value]="'searchList.selectors.channelOptions.' + o | translate">
        </app-custom-select-option>
      </app-custom-select>

      <app-custom-select [fxFlex]="getFlexValue('rating')" class="custom-select f-formfield-label clickable"
        [placeholder]="'searchList.selectors.rating' | translate" [showSelected]="false"
        [assetsForDialog]="RatingDialogAssets" [selected]="rating" translatePrefix="searchList.selectors.ratingOptions."
        [formControlName]="'rating'">
        <app-custom-select-option *ngFor="let o of RatingOptions" [key]="o"
          [value]="'searchList.selectors.ratingOptions.' + o | translate">
        </app-custom-select-option>
      </app-custom-select>

      <app-custom-select [fxFlex]="getFlexValue('genre')" class="custom-select f-formfield-label clickable"
        [placeholder]="'searchList.selectors.genre' | translate" [showSelected]="false" [selected]="genre"
        translatePrefix="searchList.selectors.genreOptions." [formControlName]="'genre'">
        <app-custom-select-option *ngFor="let o of GenreOptions" [key]="o"
          [value]="'searchList.selectors.genreOptions.' + o | translate">
        </app-custom-select-option>
      </app-custom-select>
    </span>

    <span fxLayout="row" fxFlex fxFlex.xs="1 0 340px" fxFlex.sm="1 0 340px" fxLayoutGap="20px" class="pb20px"
      #searchInput fxLayoutAlign="center center">
      <mat-form-field class="text-center custom-input cb" floatLabel="never" fxFlex>
        <input matInput (blur)="onSearchInputBlur()" placeholder="{{'searchList.placeholder' | translate}}"
          formControlName="q" autocomplete="off" class="clickable">
        <a *ngIf="form.value.q" matSuffix mat-icon-button aria-label="Text löschen" [disableRipple]="true"
          (click)="clearQ($event)">
          <mat-icon class="icon-custom-c">close</mat-icon>
        </a>
      </mat-form-field>

      <mat-icon color="black" class="icon-size clickable">search</mat-icon>
    </span>
  </span>

  <span fxLayout="row wrap" *ngIf="chipsControl.length > 0" fxLayoutAlign="starts center"
    fxLayoutAlign.sm="start center" fxLayoutAlign.xs="start center" fxFlex="100" fxFill fxLayoutGap="7px"
    class="pb20px">
    <mat-basic-chip disableRipple *ngFor="let cc of chipsControl; let i = index" [selectable]="false" removable
      (click)="removeChip(cc)" (removed)="removeChip(cc)" fxLayout="row" fxLayoutAlign="center center"
      class="f-chip chip-removable mb-1 outline-none clickable">
      <span [ngSwitch]="cc.control">
        <span *ngSwitchCase="'alter'">
          {{'searchList.selectors.ageOptions.' + cc.selection | translate}}
        </span>
        <span *ngSwitchCase="'rating'">
          {{'searchList.selectors.ratingOptions.' + cc.selection | translate}}
        </span>
        <span *ngSwitchCase="'src'">
          {{'searchList.selectors.channelOptions.' + cc.selection | translate}}
        </span>
        <span *ngSwitchCase="'genre'">
          {{'searchList.selectors.genreOptions.' + cc.selection | translate}}
        </span>
      </span>
      <mat-icon matChipRemove fxLayout="row" fxLayoutAlign="center center">close</mat-icon>
    </mat-basic-chip>
    <mat-basic-chip *ngIf="chipsControl.length > 0" fxLayout="row" disableRipple [selectable]="false"
      fxLayoutAlign="center center" [removable]="true" (click)="removeAll()"
      class="f-chip chip mb-1 outline-none clickable">{{'searchList.chips.clearAll' | translate}}
    </mat-basic-chip>
  </span>
</form>