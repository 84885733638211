import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { FlimmoMetaService } from '../../../core/services/flimmo-meta.service';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { PreviewItem, Source, Swimlane } from '../../../generated-api';
import { formatDate } from '../../functions/utils';
import { Router } from '@angular/router';
import * as Constants from '../../../core/helpers/constants/constants'
import { BesprechungChannelTypes } from '../../../core/helpers/models/besprechungChannelTypes';
import ItemclassEnum = PreviewItem.ItemclassEnum;
import SwimlaneclassEnum = Swimlane.SwimlaneclassEnum;
import { ScrollTrackerService } from '../../directives/scroll-tracker/scroll-tracker.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'swimlanes',
  templateUrl: './swimlanes.component.html',
  styleUrls: ['./swimlanes.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class SwimlanesComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @Input() swimlane: Swimlane;
  @Input() loading = false;
  @Input() bigCards = false;
  @ViewChildren('items') items;
  @ViewChild('container') container: ElementRef;
  @Input() tvLane = false;

  sic = ItemclassEnum;
  subs = new SubSink();
  isHandset = false;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  currentDate = formatDate(new Date());
  private leftAtLimit = true;
  private rightAtLimit = false;
  private cardWidth = 160;
  private gap = 15;


  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.cr.detectChanges();
    this.cr.markForCheck();
  }

  readonly PLACEHOLDER_IMAGE_WITH_PATH = Constants.PLACEHOLDER_IMAGE_WITH_PATH;

  constructor(public flimmoMeta: FlimmoMetaService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private scrollTrackerService: ScrollTrackerService,
    private cr: ChangeDetectorRef) {
  }

  ngAfterViewInit(): void {
    this.cr.detectChanges();
    this.cr.markForCheck();
  }

  ngOnInit(): void {
    this.subs.sink = this.isHandset$.subscribe(value => {
      this.isHandset = value;
    });
    this.subs.sink = this.scrollTrackerService.updateComponent$.subscribe(value => {
      if (value && this.container) {
        this.updateLimits();
      }
    });
    if (this.bigCards) {
      this.cardWidth = 261;
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.cr.detectChanges();
    this.cr.markForCheck();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  scrollLeft(): void {
    const maxScrollLeft = this.container.nativeElement.scrollWidth - this.container.nativeElement.clientWidth;

    var delta = 0
    if (this.container.nativeElement.clientWidth < 900) {
      let cardCount = Math.floor((this.container.nativeElement.clientWidth) / (this.cardWidth + this.gap))
      delta = cardCount * (this.cardWidth + this.gap)
      if (this.rightAtLimit) {
        delta = delta + this.gap
      }
      if (this.container.nativeElement.scrollLeft - delta < 25) {
        delta = this.container.nativeElement.scrollLeft
      }
    } else {
      delta = (this.container.nativeElement.clientWidth - 25)
      if (maxScrollLeft == this.container.nativeElement.scrollLeft) {
        delta = (this.container.nativeElement.clientWidth - 60)
      }
    }

    this.container.nativeElement.scroll({
      left: this.container.nativeElement.scrollLeft - delta,
      behavior: 'smooth',
    });

    this.leftAtLimit = false
    this.rightAtLimit = false
    if (this.container.nativeElement.scrollLeft - delta <= 0) {
      this.leftAtLimit = true
    }
  }

  scrollRight(): void {

    var delta = 0
    if (this.container.nativeElement.clientWidth < 900) {
      let cardCount = Math.floor((this.container.nativeElement.clientWidth) / (this.cardWidth + this.gap))
      delta = cardCount * (this.cardWidth + this.gap)
      if (this.leftAtLimit) {
        delta = delta + this.gap
      }
    } else {
      var delta = (this.container.nativeElement.clientWidth - 25)
    }

    this.container.nativeElement.scroll({
      left: this.container.nativeElement.scrollLeft + delta,
      behavior: 'smooth'
    });

    this.rightAtLimit = false
    this.leftAtLimit = false
    if (this.container.nativeElement.scrollLeft + delta + this.container.nativeElement.clientWidth >= this.container.nativeElement.scrollWidth) {
      this.rightAtLimit = true
    }

  }

  trackSwItems(index, dr): string {
    return dr.id;
  }


  leftVisible(): boolean {
    return !this.leftAtLimit;
  }

  rightVisible(): boolean {
    return (!this.bigCards && this.swimlane.items.length > 5 ? !this.rightAtLimit : false) || (this.bigCards && this.swimlane.items.length > 3 ? !this.rightAtLimit : false);
  }

  navTo(m: PreviewItem): void {
    if (m.itemclass === this.sic.Besprechung) {
      this.router.navigate(['/besprechung', m.id, this.flimmoMeta.prettifyUrl(m.title)]);
    }
    if (m.itemclass === this.sic.Ytbesprechung) {
      this.router.navigate(['/ytbesprechung', m.id, this.flimmoMeta.prettifyUrl(m.title)]);
    }
    if (m.itemclass === this.sic.Redtext) {
      this.router.navigate(['/redtext', m.id, this.flimmoMeta.prettifyUrl(m.title)]);
    }
    if (m.itemclass === this.sic.Special) {
      this.router.navigate(['/special', m.id, this.flimmoMeta.prettifyUrl(m.title)]);
    }
  }

  onImageError(event): void {
    event.target.src = Constants.PLACEHOLDER_IMAGE_WITH_PATH;
  }

  // TODO: possible code duplication in results components

  getText(srclist: Array<Source>): string {
    return srclist
      .sort((a, b) => this.srcSequence.indexOf(a.src) - this.srcSequence.indexOf(b.src))
      .map(value => value.srcName) // value.channel=='tv' ? '⊙ '+value.srcName : value.srcName
      .reduce((previousValue, currentValue) => previousValue + ', ' + currentValue);
  }

  hasSrc(m: PreviewItem): boolean {
    return (m.itemclass === this.sic.Besprechung || m.itemclass === this.sic.Ytbesprechung) && m.srclist && m.srclist.length > 0;
  }

  srcSequence = [
    "kino",
    "netflix",
    "amazon",
    "disney_plus",
    "kika",
    "toggo",
    "ARD Mediathek",
    "ZDF Mediathek",
    "RTLPLUS",
    "Joyn",
    "joyn",
    "youtube",
    "youtubekids",
    "tiktok",
    "instagram",
    "tv",
    "KKA",
    "RTLS",
    "Disney Channel Free TV",
    "NICK",
    "RTL",
    "RTL2",
    "PRO7",
    "SAT1",
    "K1",
    "ARD",
    "ZDF"
  ]

  private updateLimits(): void {
    this.rightAtLimit = false
    this.leftAtLimit = false
    if (this.container.nativeElement.scrollLeft == 0) {
      this.leftAtLimit = true
    }
    if ((this.container.nativeElement.scrollWidth - this.container.nativeElement.clientWidth) == this.container.nativeElement.scrollLeft) {
      this.rightAtLimit = true
    }
  }
}
