<span fxLayout="column" fxFlex="0 1 900px" fxFlex.xs="100" fxFill fxLayoutAlign="center start"
      id="feature-{{feature?.previewItem?.id}}">
      <span fxLayout="column" fxLayoutGap="20px" [ngClass]="{ 'px20px': isHandset}" fxFill>
            <span class="mat-h1 primary-color overline-margin">{{feature?.previewItem?.topos}}</span>
            <span class="mat-h2 overline-title-gap m-0">{{feature?.previewItem?.title}}</span>
            <span fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="40px" fxFill fxFlexAlign="start start"
                  class="special-img-border" fxFlexOffset="30px">
                  <img class="object-fit-cover mb-4" [src]="feature?.previewItem?.imagehigh | safe : 'resourceUrl'"
                        [height]="isHandset ? 204: 262" [ngClass]="{'imgWidthMobile': isXs}" loading="lazy" fetchprioritiy="low" />
                  <span fxLayout="column" fxFlex fxFlex.sm="1 0 320px" fxFlex.xs="100" fxLayoutGap="20px"
                        fxLayoutAlign="start">
                        <span class="mb1">{{feature?.previewItem?.summary}}</span>

                        <a *ngIf="feature?.button?.uri != null" class="mt-1 d-flex text-decoration-none black"
                              fxFlex="36px" fxLayout="row" fxLayoutAlign="center center"
                              [routerLink]="[feature?.button?.uri]">
                              <div fxFlex="0 0 160px" fxLayoutAlign="center center"
                                    class="align-self-center font-weight-bold flimmo-outline-button">
                                    {{feature?.button?.label}}</div>
                        </a>
                  </span>
            </span>
      </span>

      <swimlanes fxFlexOffset="20px" [swimlane]="feature?.swimlane"></swimlanes>

</span>
