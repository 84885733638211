import {Component, Inject, OnDestroy, ViewChild, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {SubSink} from 'subsink';
import {MarkdownComponent} from 'ngx-markdown';
import {LinkHandlerService} from '../../../core/services/markdown-link-handler.service';
import {GlossaryService} from '../../../generated-api/api/glossary.service';

@Component({
  selector: 'app-glossary-dialog',
  templateUrl: './glossary-dialog.component.html',
  styleUrls: ['./glossary-dialog.component.scss']
})
export class GlossaryDialogComponent implements OnInit, OnDestroy {
  term: any;
  definition: any;
  subs = new SubSink();

  @ViewChild('mk') markdown: MarkdownComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private glossaryService: GlossaryService,
              public dialogRef: MatDialogRef<GlossaryDialogComponent>,
              public lh: LinkHandlerService) {
  }

  ngOnInit(): void {
    this.subs.sink = this.glossaryService.getGlossary(this.data.name).subscribe(value => {
      this.term = value.term;
      this.definition = value.definition;
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
