import {Injectable} from '@angular/core';
import {MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams): any {
    // specific fallback solution for src chips/popups, when a new src is provided by BE the FE doesn't know
    if (params.key.split('.').splice(-2, 1).pop() === 'channelOptions') {
      return params.key.split('.').pop();
    }
    return params.key;
  }
}
