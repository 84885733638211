import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from './core/pages/error-page/error-page.component';
import { UeberFlimmoComponent } from './core/pages/ueber-flimmo/ueber-flimmo.component';
import { FrontpagePageComponent } from './modules/frontpage/pages/frontpage-page/frontpage-page.component';
import { RedirectComponent } from './core/pages/redirect/redirect.component';

const routes: Routes = [
  {
    path: '',
    component: FrontpagePageComponent,
  },
  {
    path: 'exit',
    component: RedirectComponent
  },
  {
    path: 'frontpagePreview/:id',
    component: FrontpagePageComponent,
  },
  {
    path: 'besprechung',
    loadChildren: () => import('./modules/besprechung/besprechung.module').then(m => m.BesprechungModule)
  },
  {
    path: 'redtext',
    loadChildren: () => import('./modules/redtext/redtext.module').then(m => m.RedtextModule)
  },
  {
    path: 'ytbesprechung',
    loadChildren: () => import('./modules/ytbesprechung/ytbesprechung.module').then(m => m.YtbesprechungModule)
  },
  {
    path: 'special',
    loadChildren: () => import('./modules/special/special.module').then(m => m.SpecialModule)
  },
  {
    path: 'tv',
    loadChildren: () => import('./modules/tv/tv.module').then(m => m.TVModule)
  },
  {
    path: 'editor',
    loadChildren: () => import('./modules/editor/editor.module').then(m => m.EditorModule)
  },
  {
    path: 'error',
    component: ErrorPageComponent
  },
  {
    path: 'ueber-flimmo',
    component: UeberFlimmoComponent
  },
  {
    path: 'flyer',
    loadChildren: () => import('./modules/flyer-order/flyer-order.module').then(m => m.FlyerOrderModule)
  },

  // old flimmo link rediects
  { path: 'iframe', redirectTo: 'tv' }, // tv embedded as iframe on external sites


  {
    path: '**',
    component: RedirectComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
