import {Component, Input, OnInit} from '@angular/core';
import {PreviewItem} from '../../../../generated-api';

@Component({
  selector: 'app-special',
  templateUrl: './special.component.html',
  styleUrls: ['./special.component.scss']
})
export class SpecialComponent implements OnInit {
  @Input() special: PreviewItem;
  @Input() isHandset: boolean;
  @Input() isXs: boolean;
  constructor() { }

  ngOnInit(): void {
  }
}
