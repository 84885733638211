import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export interface RouteScrollPositions {
  [url: string]: RouteScrollPosition[];
}

export interface RouteScrollPosition {
  position: number;
  elementId: string;
}

@Injectable(
  {
    providedIn: 'root'
  }
)
export class ScrollTrackerService {
  private routeScrollPositions: RouteScrollPositions = {};
  private updateComponent = new BehaviorSubject<boolean>(false);
  public updateComponent$ = this.updateComponent.asObservable();

  constructor() {
  }

  saveScroll(url: string, scrollPosition: RouteScrollPosition): void {
    if (!this.routeScrollPositions[url]) {
      this.routeScrollPositions[url] = [];
    }
    const posIdx = this.routeScrollPositions[url].findIndex(value => value.elementId === scrollPosition.elementId);
    if (posIdx > -1) {
      this.routeScrollPositions[url][posIdx] = scrollPosition;
    } else {
      this.routeScrollPositions[url].push(scrollPosition);
    }
  }

  getScroll(url: string): RouteScrollPosition[] {
    return this.routeScrollPositions[url];
  }

  /**
   * If scroll position change needs to update parent, you can use this to signal
   */
  signalParentToUpdate(): void {
    this.updateComponent.next(true);
  }
}
