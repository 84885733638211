import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { SubSink } from 'subsink';
import {
  ageOptions,
  channelOptions,
  genreOptions,
  ratingDialogAssets,
  ratingOptions
} from '../../../../core/helpers/select-options/searcher-options';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FlimmoMetaService } from '../../../../core/services/flimmo-meta.service';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, tap } from 'rxjs/operators';
import isArray from 'lodash-es/isArray';
import { deepCopy } from '../../../../shared/functions/utils';
import sortBy from 'lodash-es/sortBy';
import filter from 'lodash-es/filter';
import difference from 'lodash-es/difference';
import union from 'lodash-es/union';
import { SearchService } from '../../services/search.service';
import { SearchStoreService } from '../../stores/search-store.service';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy {

  private isBrowser: boolean;

  subs = new SubSink();
  AgeOptions = ageOptions;
  GenreOptions = genreOptions;
  RatingOptions = ratingOptions;
  ChannelOptions = channelOptions;
  RatingDialogAssets = ratingDialogAssets;
  firstChange = true;
  lastChange = null;
  form = this.fb.group({
    q: [null, [
      Validators.minLength(2)]
    ],
    mode: [1],
    alter: [[]],
    genre: [[]],
    src: [[]],
    rating: [[]]
  });
  @Input() loading = false;
  @Input() searchError: string;

  chipsControl = [];
  filterNames: string[];
  rowCount = 0;

  get alter(): any {
    return this.form.controls.alter.value ? [...this.form.controls.alter.value] : [];
  }

  get rating(): any {
    return this.form.controls.rating.value ? [...this.form.controls.rating.value] : [];
  }

  get src(): any {
    return this.form.controls.src.value ? [...this.form.controls.src.value] : [];
  }

  get genre(): any {
    return this.form.controls.genre.value ? [...this.form.controls.genre.value] : [];
  }

  constructor(private fb: UntypedFormBuilder,
    private searchService: SearchService,
    private store: SearchStoreService,
    private flimmoMetaService: FlimmoMetaService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) platformId: Object) {

    this.isBrowser = isPlatformBrowser(platformId);
  }

  isFirefox(): boolean {
    if (!this.isBrowser) return false;
    const userAgent = navigator.userAgent.toLowerCase();
    return (userAgent.indexOf('firefox') > -1)
  }

  isSafari(): boolean {
    if (!this.isBrowser) return false;
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf('safari') > -1 && userAgent.indexOf('chrome') === -1;
  }

  getFlexValue(selector): string {
    if (this.isFirefox() || this.isSafari()) {
      if (selector == 'alter') return '61px';
      if (selector == 'src') return '83px';
      if (selector == 'rating') return '96px';
      if (selector == 'genre') return '67px';
      if (selector == 'gap') return '6px';
    } else {
      if (selector == 'alter') return '59px';
      if (selector == 'src') return '81px';
      if (selector == 'rating') return '94px';
      if (selector == 'genre') return '65px';
      if (selector == 'gap') return '12px';
    }
  }

  get q(): string {
    return this.form.controls.q.value;
  }

  ngOnInit(): void {
    this.queryParamChanges();
    this.formChanges();
    this.subs.sink = this.form.controls.src.valueChanges.pipe(tap(value => {
      if (isArray(value)) {
        if (value.findIndex(value2 => {
          return value2 === 'Joyn';
        }) > -1 && value.findIndex(value3 => {
          return value3 === 'joyn';
        }) === -1) {
          this.form.controls.channels.patchValue([...this.form.controls.channels.value, 'joyn']);
        }
      } else if (value === 'Joyn') {
        this.form.controls.channels.patchValue([this.form.value.channels, 'joyn']);
      }
    })).subscribe();
  }

  private formChanges(): void {
    this.subs.sink = this.form.valueChanges.pipe(debounceTime(300), tap(changes => {
      if (!this.store.sameAsLastSearch(changes, this.lastChange)) {
        this.store.resultCurrentIndex = null;
        this.updateNavigation();
        this.lastChange = changes;
      }
    })).subscribe();

  }

  private queryParamChanges(): void {
    this.subs.sink = this.activatedRoute.queryParams.subscribe(value => {
      this.form.reset();
      this.chipsControl = [];
      const q = value.q;
      const alterArray = value.alter ? !Array.isArray(value.alter) ? [value.alter] : [...value.alter] : null;
      const ratingArray = value.rating ? !Array.isArray(value.rating) ? [value.rating] : [...value.rating] : null;
      const srcArray = value.src ? !Array.isArray(value.src) ? [value.src] : [...value.src] : null;
      const genreArray = value.genre ? !Array.isArray(value.genre) ? [value.genre] : [...value.genre] : null;

      this.form.patchValue(
        {
          ...value,
          q,
          alter: alterArray,
          rating: ratingArray,
          src: srcArray,
          genre: genreArray
        }
      );
      this.cdr.markForCheck();
      this.cdr.detectChanges();
      Object.keys(this.form.value).forEach(value1 => {
        let currentchips = [];
        if (isArray(this.form.value[value1])) {
          currentchips = this.form.value[value1].map(v => {
            return { control: value1, selection: v };
          });
        }
        this.chipsControl = this.chipsControl.concat(currentchips);
      });
      const sameAsLastSearch = this.store.sameAsLastSearch({ ...this.form.value, offset: this.store.offset, limit: 40 });
      if (!sameAsLastSearch && ((q && q.length > 0)
        || (alterArray && alterArray.length > 0)
        || (srcArray && srcArray.length > 0)
        || (ratingArray && ratingArray.length > 0))
        || (genreArray && genreArray.length > 0)) {
        this.store.resetStore();
        this.searchForQuery();
      } else {
        if (this.store.searchResults && this.store.searchResults.length > 0) {
          this.store.searchResults = deepCopy(this.store.searchResults);
        } else {
          this.store.searchResults = null;
        }
      }
    });
  }


  searchForQuery(): void {
    if (
      (this.q && this.q.length >= 2) || !this.q || this.q.length === 0
      || (this.form.value.alter?.length > 0
        || this.form.value.src?.length > 0
        || this.form.value.rating?.length > 0
        || this.form.value.genre?.length > 0)
    ) {
      const v = this.form.value;
      const src = v.src ?? undefined;
      this.store.resetStore();
      this.searchService.search(
        this.q,
        v.rating ?? undefined,
        v.alter ?? undefined,
        src,
        v.genre,
        0,
        SearchService.LIMIT
      );
    }
  }

  updateNavigation(): void {
    if (this.q && this.q.length >= 2 || (!this.q && !this.form.get('q').dirty) || (!this.q && this.form.get('q').touched)) {
      if (this.noSearchParams()) {
        this.router.navigate([]);
      } else {

        const newParams = { ...this.form.value };

        this.firstChange = false;
        this.router.navigate([], { queryParams: newParams });
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  selectAll(groupName: string): void {
    if (groupName === 'streaming') {
      const sortedForm = sortBy(filter(this.form.value.src, (v) => {
        return v !== 'joyn';
      }));

      if (difference(sortBy(channelOptions.streaming), sortedForm).length === 0) {
        this.form.patchValue({
          src: this.form.controls.src.value.filter(
            function (e) {
              return this.indexOf(e) < 0;
            },
            channelOptions.streaming
          )
        });
      } else {
        this.form.patchValue({
          src: union([...this.form.value.src ?? []], [...channelOptions.streaming])
        });
      }

    }
    if (groupName === 'tv') {
      if (difference(sortBy(channelOptions.tv), sortBy(this.form.value.src)).length === 0) {
        this.form.patchValue({
          src: this.form.controls.src.value.filter(
            function (e) {
              return this.indexOf(e) < 0;
            },
            channelOptions.tv
          )
        });
      } else {
        this.form.patchValue({
          src: union([...this.form.value.src ?? []], [...channelOptions.tv])
        });
      }
    }

    if (groupName === 'social') {
      if (difference(sortBy(channelOptions.social), sortBy(this.form.value.src)).length === 0) {
        this.form.patchValue({
          src: this.form.controls.src.value.filter(
            function (e) {
              return this.indexOf(e) < 0;
            },
            channelOptions.social
          )
        });
      } else {
        this.form.patchValue({
          src: union([...this.form.value.src ?? []], [...channelOptions.social])
        });
      }
    }

    this.updateNavigation();
  }

  clearQ($event: any): void {
    $event.stopPropagation();
    this.form.get('q').patchValue('');
  }

  removeChip(c: any): void {
    this.chipsControl = this.chipsControl.filter(value => value !== c);
    const control = this.form.get(c.control);
    control.patchValue(control.value.filter(value => value !== c.selection));
    this.updateNavigation();
  }

  removeAll(): void {
    SearchStoreService.isCurrentShowsChecked = true;
    this.chipsControl = [];
    this.form.reset();
    this.firstChange = true;
    this.updateNavigation();
  }

  onSearchInputBlur(): void {
    if (this.noSearchParams()) {
      this.router.navigate([]);
    }
  }

  private noSearchParams(): boolean {
    const alter = this.form.get('alter').value;
    const src = this.form.get('src').value;
    const rating = this.form.get('rating').value;
    const genre = this.form.get('genre').value;

    return ((this.q && this.q.length === 0) || !this.q)
      && ((alter && alter.length === 0) || !alter)
      && ((src && src.length === 0))
      && ((rating && rating.length === 0) || !rating)
      && ((genre && genre.length === 0) || !genre);
  }
}
