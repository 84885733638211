export const ageOptions: string[] = [
  '3', '5', '7', '9', '11'
];

/**
 * Please update both object if the rating key changes !
 */
export const ratingOptions: string[] = [
  "4", "3", "2", "1"
];
export const ratingDialogAssets = [
  { key: "1", imgPath: null, iconClass: 'mat-danger', iconName: 'circle' },
  { key: "2", imgPath: null, iconClass: 'mat-warning', iconName: 'circle' },
  { key: "3", imgPath: null, iconClass: 'mat-success', iconName: 'circle' },
  { key: "4", imgPath: 'assets/media/rating_badge.svg', iconClass: null, iconName: null },
];

export const genreOptions: string[] = [
  'Serie', // Serie
  'Spielfilm', // Film
  'Animationsserie', // Animationsserie
  'Animationsfilm', // Animationsfilm
  'Magazin,Nachrichten', // Magazin/Nachrichten (die Schlagworte müssen zusammengefasst werden)
  'Doku', // Doku
  'Show', // Show
];


export const channelOptions: any = {
  streaming: [
    'netflix',
    'amazon',
    'disney_plus',
    'kika',
    'toggo',
    'ARD Mediathek',
    'ZDF Mediathek',
    'RTLPLUS',
    'Joyn',
  ],
  tv: [
    'KKA',
    'RTLS',
    'Disney Channel Free TV',
    'NICK',
    'RTL',
    'RTL2',
    'PRO7',
    'SAT1',
    'K1',
    'ARD',
    'ZDF'
  ],
  kino: ['kino'],
  social: ['youtube', 'tiktok', 'instagram']

}
  ;
