import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PreviewItem } from '../../../generated-api';
import xor from 'lodash-es/xor';

@Injectable({
  providedIn: 'root'
})
export class SearchStoreService {
  private readonly _searchParams = new BehaviorSubject<any>(null);
  private readonly _rowCount = new BehaviorSubject<number>(null);

  readonly searchParams$ = this._searchParams.asObservable();
  readonly rowCount$ = this._rowCount.asObservable();

  static isCurrentShowsChecked = true;
  static isCurrentShowsCheckedDirty = false;


  get searchParams(): any {
    return this._searchParams.getValue();
  }

  set searchParams(val: any) {
    this._searchParams.next(val);
  }

  private readonly _noMoreResults = new BehaviorSubject<any>(null);
  readonly noMoreResults$ = this._noMoreResults.asObservable();

  get noMoreResults(): any {
    return this._noMoreResults.getValue();
  }

  set noMoreResults(val: any) {
    this._noMoreResults.next(val);
  }


  readonly _rowCount$ = this._rowCount.asObservable();


  get rowCount(): number {
    return this._rowCount.getValue();
  }

  set rowCount(val: number) {
    this._rowCount.next(val);
  }

  private readonly _offset = new BehaviorSubject<number>(null);
  readonly offset$ = this._offset.asObservable();

  get offset(): number {
    return this._offset.getValue();
  }

  set offset(val: number) {
    this._offset.next(val);
  }

  private readonly _resultCurrentIndex = new BehaviorSubject<any>(null);
  readonly resultCurrentIndex$ = this._resultCurrentIndex.asObservable();

  get resultCurrentIndex(): any {
    return this._resultCurrentIndex.getValue();
  }

  set resultCurrentIndex(val: any) {
    this._resultCurrentIndex.next(val);
  }

  private readonly _searchResults = new BehaviorSubject<PreviewItem[]>(null);
  readonly searchResults$ = this._searchResults.asObservable();

  get searchResults(): PreviewItem[] {
    return this._searchResults.getValue();
  }

  set searchResults(val: PreviewItem[]) {
    this._searchResults.next(val);
  }

  private readonly _loading = new BehaviorSubject<boolean>(false);
  readonly loading$ = this._loading.asObservable();

  get loading(): boolean {
    return this._loading.getValue();
  }

  set loading(val: boolean) {
    this._loading.next(val);
  }


  private readonly _searchError = new BehaviorSubject<string>(null);
  readonly searchError$ = this._searchError.asObservable();

  get searchError(): string {
    return this._searchError.getValue();
  }

  set searchError(val: string) {
    this._searchError.next(val);
  }

  resetStore(): void {
    this.searchResults = null;
    this.searchParams = null;
    this.loading = false;
    this.searchError = null;
  }

  sameAsLastSearch(val, otherSearch?): boolean {

    if (SearchStoreService.isCurrentShowsCheckedDirty) {
      SearchStoreService.isCurrentShowsCheckedDirty = false;
      return false;
    }

    let lastSearch = this.searchParams;
    if (otherSearch) {
      lastSearch = otherSearch;
    }
    if (!lastSearch) {
      return false;
    }
    if (val.offset || val.limit || lastSearch.offset || lastSearch.limit) {
      return (lastSearch.q === val.q || (!lastSearch.q && !val.q))
        && xor(lastSearch.alter, val.alter).length === 0
        && xor(lastSearch.src, val.src).length === 0
        && xor(lastSearch.rating, val.rating).length === 0
        && xor(lastSearch.genre, val.genre).length === 0
        && (val.offset === lastSearch.offset);
    } else {
      return (lastSearch.q === val.q || (!lastSearch.q && !val.q))
        && xor(lastSearch.alter, val.alter).length === 0
        && xor(lastSearch.src, val.src).length === 0
        && xor(lastSearch.rating, val.rating).length === 0
        && xor(lastSearch.genre, val.genre).length === 0;
    }
  }

  constructor() {
  }
}
