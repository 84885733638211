import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-traffic-lights',
  templateUrl: './traffic-lights.component.html',
  styleUrls: ['./traffic-lights.component.scss']
})
export class TrafficLightsComponent implements OnInit, OnChanges {
  showLight = true;

  getAsset = TrafficLightsComponent.getImageAssetByRating;
  getAlt = TrafficLightsComponent.getImageAssetAltTextTranslationForTrafficStringByRating;

  static getImageAssetByRating(rating): string {
    switch (rating) {
      case 1:
        return 'assets/media/trafficlight_red.svg';
      case 2:
        return 'assets/media/trafficlight_yellow.svg';
      case 3:
        return 'assets/media/trafficlight_green.svg';
      case 4:
        return 'assets/media/trafficlight_green.svg';
      default:
        return '';
    }
  }

  static getImageAssetAltTextTranslationForTrafficStringByRating(rating): string {
    switch (rating) {
      case 1:
        return 'searchList.selectors.ratingOptions.1';
      case 2:
        return 'searchList.selectors.ratingOptions.2';
      case 3:
        return 'searchList.selectors.ratingOptions.3';
      case 4:
        return 'searchList.selectors.ratingOptions.3';
      default:
        return '';
    }
  }

  @Input() rating;
  @Input() height = 58;
  @Input() width = 58;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rating) {
      this.showLight =
        changes.rating.currentValue !== null &&
        changes.rating.currentValue !== 0 &&
        changes.rating.currentValue !== undefined;
    }
  }
}
