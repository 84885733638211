import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() isHandset = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  getVersion(): any {
    return environment.appVersion;
  }
}
