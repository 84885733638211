<span fxLayout="row" fxLayoutAlign="center center" class="h-100  mat-app-background-1">
  <div fxLayout="row" fxFlex="0 1 900px" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign="space-between center"
    [ngStyle.sm]="{'padding-right.px': 20}" fxLayoutGap="20px" fxFill>
    <a (click)="toFrontpage()" tabindex="0" aria-label="Zur Einstiegseite" role="button"
      class="text-decoration-none black clickable primary-color text-wrap outline-none" fxLayout="row"
      fxLayout.xs="column" [ngClass]="{'mb-3': isHandset}" [ngClass.sm]="'pl20px'" [ngClass.xs]="'pl20px'"
      fxFlexAlign="start start" fxLayoutGap="10px" fxLayoutGap.xs="4px">
      <img ngSrc="assets/media/provisional_logo.svg" priority width=134 height=24
        [alt]="'layout.header.logoText' | translate">
      <span fxLayout="column" class="f-slogan primary-color" [ngStyle]="{'min-width.px':152}">
        <div>{{'layout.header.sloganPart1' | translate}}</div>
        <div>{{'layout.header.sloganPart2' | translate}}</div>
      </span>
    </a>
    <div fxLayout="row" fxLayoutAlign="center center">
    <a aria-label="Flimmo auf Instagram folgen" role="link" fxLayoutAlign="center center" class="p10px"  href="https://www.instagram.com/flimmo.elternratgeber/" target="_blank">
      <img ngSrc="assets/media/insta.svg"  width=20 height=20  [alt]="'layout.header.insta' | translate">
    </a>
    <a aria-label="Flimmo auf Facebook folgen" role="link" fxLayoutAlign="center center" class="p10px" href="https://www.facebook.com/flimmo.elternratgeber/" target="_blank">
      <img ngSrc="assets/media/fb.svg"  width=16 height=16 [alt]="'layout.header.facebook' | translate">
    </a>
    <a aria-label="Flimmo auf Whatsapp folgen" role="link" fxLayoutAlign="center center" class="p10px" href="https://whatsapp.com/channel/0029VauNZHx6xCSPLGZnRZ0t" target="_blank">
      <img ngSrc="assets/media/whatsapp.svg"  width=20 height=20 [alt]="'layout.header.whatsapp' | translate">
    </a>
    </div>
    <span fxLayout="row">
      <span fxLayout="row" fxLayoutGap="10px" *ngIf="!isHandset">
        <span fxLayout="row" fxFlexAlign="center center" class="align-self-center" fxLayoutGap="10px">
          <a fxLayout="row" fxFlexAlign="center center"
            class="align-items-center justify-content-center flimmo-outline-button f-button-label text-decoration-none custom-header-button"
            routerLink="/redtext/101469/Mediennutzung-kompetent-begleiten">{{'layout.header.menuItems.redtext' | translate | uppercase}}</a>
          <a fxLayout="row" fxFlexAlign="center center"
            class="align-items-center text-nowrap justify-content-center flimmo-outline-button f-button-label text-decoration-none custom-header-button"
            routerLink="/ueber-flimmo">{{'layout.header.menuItems.about' | translate | uppercase}}</a>
        </span>
      </span>
      <span>
        <button mat-icon-button [matMenuTriggerFor]="navmenu" *ngIf="isHandset" disableRipple
          [ngClass]="{'mr-3': isHandset}" aria-label="Menü">
          <mat-icon class="menu-button" color="primary">menu</mat-icon>
        </button>
      </span>
    </span>
  </div>
</span>

<mat-menu #navmenu="matMenu" class="mat-app-background-1">
  <a class="text-decoration-none mat-transparent black f-hint-link" mat-menu-item
    routerLink="/redtext/101469/Mediennutzung-kompetent-begleiten">{{'layout.header.menuItems.redtext' | translate | uppercase}}</a>
  <a class="text-decoration-none mat-transparent black f-hint-link button-border" mat-menu-item
    routerLink="/ueber-flimmo">{{'layout.header.menuItems.about' | translate | uppercase}}</a>
</mat-menu>
