import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { DefaultService } from '../../../generated-api';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})

export class RedirectComponent implements OnInit {
  constructor(private defaultService: DefaultService, private router: Router, private route: ActivatedRoute) {

  }
  ngOnInit(): void {
    const url = this.route.snapshot.queryParamMap.get('url');
    if (url != null) {
      window.location.href = decodeURIComponent(url);
    } else {
      const redirectPath = this.route.snapshot.url[0].path;

      if (redirectPath != null) {
        this.defaultService.getAlias(redirectPath).pipe(catchError(err => {
          return of(err);
        })).subscribe(value => {
          if (value.urlPath) {
            window.location.href = value.urlPath;
          }
        });
      }
    }
  }


  close(): void {
    window.close();
  }
}
