import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {CustomDropdownComponent} from './custom-dropdown/custom-dropdown.component';
import {CustomSelectOptionComponent} from './custom-select-option/custom-select-option.component';
import {CustomDropdownService} from './custom-dropdown/custom-dropdown.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map, shareReplay} from 'rxjs/operators';
import {SubSink} from 'subsink';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {CustomSelectDialogComponent} from './custom-select-dialog/custom-select-dialog.component';
import {ActiveDescendantKeyManager} from '@angular/cdk/a11y';
import {Overlay} from '@angular/cdk/overlay';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSelectComponent),
      multi: true
    },
    CustomDropdownService
  ]
})
export class CustomSelectComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor {
  public isHandset = false;
  private dialogRef: MatDialogRef<any>;
  private keyManager: ActiveDescendantKeyManager<any>;

  constructor(private dropdownService: CustomDropdownService,
              private breakpointObserver: BreakpointObserver,
              private dialog: MatDialog,
              private overlay: Overlay,
              private cdr: ChangeDetectorRef) {
    this.dropdownService.register(this);
  }

  subs = new SubSink();
  @Input()
  public label: string;

  @Input()
  public assetsForDialog: any[];

  @Input()
  public placeholder: string;

  @Input()
  public translatePrefix: string;

  @Input()
  public selected: any[] = [];

  @Input()
  public required = false;

  @Input()
  public disabled = false;

  @Input()
  public srcMode = false;

  @ViewChild('input')
  public input: ElementRef;

  @ViewChild(CustomDropdownComponent)
  public dropdown: CustomDropdownComponent;

  @ContentChildren(CustomSelectOptionComponent)
  public options: QueryList<CustomSelectOptionComponent>;

  public selectedOption: CustomSelectOptionComponent[] = [];

  public displayText: string = '';

  @Input() showSelected: any = true;

  public ngAfterViewInit(): void {
    setTimeout(() => {
      // if (this.selected) {
      //   this.syncOptionsToSelected();
      // }
      this.syncOptionsToSelected();
      this.setDisplayText();

      this.keyManager = new ActiveDescendantKeyManager(this.options)
        .withHorizontalOrientation('ltr')
        .withVerticalOrientation()
        .withWrap();

    });
  }

  private syncOptionsToSelected(): void {
    this.selectedOption = [];
    if (this.options && this.selected && this.selected.length > 0) {
      this.selected.forEach(value => {
        const optionFound = this.options.toArray().find(option => option.key === value);
        if (optionFound) {
          this.selectedOption.push(optionFound);
        }
      });
    } else {
      this.selectedOption = [];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selected']) {
      this.syncOptionsToSelected();
    }
  }

  private setDisplayText(): void {
    this.displayText = '';
    if (this.selectedOption && this.selectedOption.length > 0) {
      this.displayText = this.selectedOption.reduce((acc: any, previousValue) => {
        return acc + ' ' + previousValue.key;
      }, '');
    }
  }

  public showDropdown(): void {
    this.dropdown.show();

    if (!this.options.length) {
      return;
    }
    this.keyManager && this.keyManager.setFirstItemActive();

  }

  ngOnInit(): void {
    this.subs.sink = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
      .pipe(
        map(result => result.matches),
        shareReplay()
      ).subscribe(value => {
        this.isHandset = value;
      });
  }

  private toggleSelection(option: CustomSelectOptionComponent): void {
    if (this.selected && this.selected.length > 0) {
      const itemIdx = this.selected.findIndex(value => value === option.key);
      if (itemIdx > -1) {
        this.selected.splice(itemIdx, 1);
      } else {
        this.selected.push(option.key);
      }
    } else {
      this.selected.push(option.key);
    }
  }

  public selectOption(option: CustomSelectOptionComponent): void {
    this.keyManager.setActiveItem(option);
    this.toggleSelection(option);
    this.toggleSelectedOptions(option);
    this.setDisplayText();
    // this.hideDropdown();
    this.input.nativeElement.focus();
    this.onChange();
  }

  public hideDropdown(): void {
    this.dropdown.hide();
  }

  public onKeyDown(event: KeyboardEvent): void {
    if (['Enter', ' ', 'ArrowDown', 'Down', 'ArrowUp', 'Up'].indexOf(event.key) > -1) {
      if (!this.dropdown.showing) {
        this.showDropdown();
        return;
      }

      if (!this.options.length) {
        event.preventDefault();
        return;
      }
    }

    if (event.key === 'Enter' || event.key === ' ') {
      this.toggleSelection(this.keyManager.activeItem);
      this.toggleSelectedOptions(this.keyManager.activeItem);
      this.setDisplayText();
      this.onChange();
    } else if (event.key === 'Escape' || event.key === 'Esc') {
      this.dropdown.showing && this.hideDropdown();
    } else if (['ArrowUp', 'Up', 'ArrowDown', 'Down', 'ArrowRight', 'Right', 'ArrowLeft', 'Left']
      .indexOf(event.key) > -1) {
      this.keyManager.onKeydown(event);
    } else if (event.key === 'PageUp' || event.key === 'PageDown' || event.key === 'Tab') {
      this.dropdown.showing && event.preventDefault();
    }
  }

  public onChangeFn = (_: any) => {
  }

  public onTouchedFn = () => {
  }

  public registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(obj: any): void {
    this.selected = obj || [];
  }

  public onTouched(): void {
    this.onTouchedFn();
  }

  public onChange(): void {
    this.onChangeFn(this.selected);
  }

  private toggleSelectedOptions(option: CustomSelectOptionComponent): void {
    if (this.selectedOption && this.selectedOption.length > 0) {
      const itemIdx = this.selectedOption.findIndex(value => value === option);
      if (itemIdx > -1) {
        this.selectedOption.splice(itemIdx, 1);
      } else {
        this.selectedOption.push(option);
      }
    } else {
      this.selectedOption.push(option);
    }
  }

  syncSelectedWithOptions(): void {
    if (this.selectedOption) {
      this.selected = this.selectedOption.map(value => {
        return value.key;
      });
    }
  }

  openDialog(): void {

    this.dialogRef = this.dialog.open(CustomSelectDialogComponent, {
      width: '300px',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      data: {
        name: this.placeholder,
        options: this.options.toArray(),
        selection: this.selectedOption,
        translatePrefix: this.translatePrefix,
        assets: this.assetsForDialog,
        minHeight: this.dialogHeightByName(this.placeholder),
        mode: this.srcMode ? 'customSrc' : 'normal'
      }
    });

    this.dialogRef.afterClosed().subscribe(value => {
      if (value && value.search) {
        this.selectedOption = [...value.selection];
        this.syncSelectedWithOptions();
        this.onChange();
      }
    });
  }

  private dialogHeightByName(placeholder: string): any {
    switch (placeholder) {
      case 'Bewertung':
        return 150;
      case 'Alter':
        return 190;
      default:
        return null;
    }
  }
}
