<span fxLayout="row" fxLayoutAlign="center start" fxFlex="100" class="mat-app-background-1">
  <span fxLayout="column" fxFlex="0 1 900px" fxFlex.sm="100" fxFlex.xs="100" fxLayoutGap="20px"
    class="position-relative">
    <span fxLayout="column" class="image-ratio-container position-relative m-0" fxFill
      [ngClass]="{'imgWeb': !isHandset}">
      <img class="object-fit-cover absolute-zero" width="100%" [src]="isHandset ? imgMobile : imgWeb" />
    </span>
    <span fxLayout="column" fxFlex.sm="100" fxFlex.xs="100">
      <span [ngClass]="{ 'px20px': isHandset}"
        class="mat-h1 primary-color overline-margin image-overline-gap">{{'static.ueber-flimmo.overline' |
        translate}}</span>
      <span [ngClass]="{ 'px20px': isHandset}" class="title-content-gap mb1 pb33px">{{'static.ueber-flimmo.p1' |
        translate}} <a style="color: #ff522e;" href="/flyer">www.flimmo.de/flyer</a></span>
      <span [ngClass]="{ 'px20px': isHandset}" class="mt-1 mb1 pb33px">{{'static.ueber-flimmo.p2' |
        translate}}</span>
      <a class="bg-white p-3 text-decoration-none black" fxLayout="row" fxLayoutAlign="space-between center"
        fxLayoutGap="13px" routerLink="/redtext/101233/Projektinformation">
        <span fxLayout="row" fxLayoutGap="13px">
          <span class="custom-container border-radius-4px" fxLayout="column" fxLayoutAlign="center center">
            <img class="icon-custom-b" [src]="image1">
          </span>
          <span fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
            <span class="mat-h3">{{'static.ueber-flimmo.item1.title' | translate}}</span>
            <span class="mb1 " style="max-width: 600px">{{'static.ueber-flimmo.item1.content' |
              translate}}</span>
          </span>
        </span>
        <a class="text-decoration-none primary-color fs34px">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </a>
      </a>
      <a class="mat-app-background-1 p-3 text-decoration-none black" fxLayout="row" fxLayoutAlign="space-between center"
        fxLayoutGap="13px" routerLink="/redtext/101234/So-bewertet-FLIMMO">
        <span fxLayout="row" fxLayoutGap="13px">
          <span class="custom-container border-radius-4px" fxLayout="column" fxLayoutAlign="center center">
            <img class="icon-custom-b" [src]="image2">
          </span>
          <span fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
            <span class="mat-h3">{{'static.ueber-flimmo.item2.title' | translate}}</span>
            <span class="mb1 " style="max-width: 554px">{{'static.ueber-flimmo.item2.content' |
              translate}}</span>
          </span>
        </span>
        <a class="text-decoration-none primary-color fs34px">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </a>
      </a>
      <a class="bg-white p-3 text-decoration-none black" fxLayout="row" fxLayoutAlign="space-between center"
        fxLayoutGap="13px" routerLink="/redtext/101235/Konzept-und-Grundlagen">
        <span fxLayout="row" fxLayoutGap="13px">
          <span class="custom-container border-radius-4px" fxLayout="column" fxLayoutAlign="center center">
            <img class="icon-custom-b" [src]="image3">
          </span>
          <span fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
            <span class="mat-h3">{{'static.ueber-flimmo.item3.title' | translate}}</span>
            <span class="mb1 " style="max-width: 500px">{{'static.ueber-flimmo.item3.content' |
              translate}}</span>
          </span>
        </span>
        <a class="text-decoration-none primary-color fs34px">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </a>
      </a>
      <a class="mat-app-background-1 p-3 text-decoration-none black" fxLayout="row" fxLayoutAlign="space-between center"
        fxLayoutGap="13px" routerLink="/redtext/101225/Herausgeber">
        <span fxLayout="row" fxLayoutGap="13px">
          <span class="custom-container border-radius-4px" fxLayout="column" fxLayoutAlign="center center">
            <img class="icon-custom-b" [src]="image4">
          </span>
          <span fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
            <span class="mat-h3">{{'static.ueber-flimmo.item4.title' | translate}}</span>
            <span class="mb1 " style="max-width: 452px">{{'static.ueber-flimmo.item4.content' |
              translate}}</span>
          </span>
        </span>
        <a class="text-decoration-none primary-color fs34px">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </a>
      </a>
      <a class="bg-white p-3 text-decoration-none black" fxLayout="row" fxLayoutAlign="space-between center"
        fxLayoutGap="13px" routerLink="/redtext/101237/Redaktion">
        <span fxLayout="row" fxLayoutGap="13px">
          <span class="custom-container border-radius-4px" fxLayout="column" fxLayoutAlign="center center">
            <img class="icon-custom-b" [src]="image5">
          </span>
          <span fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
            <span class="mat-h3">{{'static.ueber-flimmo.item5.title' | translate}}</span>
            <span class="mb1" style="max-width: 562px">{{'static.ueber-flimmo.item5.content' | translate}}</span>
          </span>
        </span>
        <a class="text-decoration-none primary-color fs34px">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </a>
      </a>
      <a class="mat-app-background-1 p-3 text-decoration-none black" fxLayout="row" fxLayoutAlign="space-between center"
        fxLayoutGap="13px" routerLink="/redtext/101228">
        <span fxLayout="row" fxLayoutGap="13px">
          <span class="custom-container border-radius-4px" fxLayout="column" fxLayoutAlign="center center">
            <img class="icon-custom-b" [src]="image6">
          </span>
          <span fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
            <span class="mat-h3">{{'static.ueber-flimmo.item6.title' | translate}}</span>
            <span class="mb1" style="max-width: 400px">{{'static.ueber-flimmo.item6.content' | translate}}</span>
          </span>
        </span>
        <a class="text-decoration-none primary-color fs34px">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </a>
      </a>
      <!--      <span [ngClass]="{ 'px20px': isHandset}" fxLayout="column" class="opaquetexts-color copyright-custom">-->
      <!--      </span>-->
    </span>
    <span fxFlex="50px"></span>
  </span>
</span>
