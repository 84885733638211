import {Component, OnDestroy, OnInit} from '@angular/core';
import {ErrorStoreService} from '../../services/error-store.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map, shareReplay} from 'rxjs/operators';
import {SubSink} from 'subsink';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {RouterEventsService} from '../../services/router-events.service';
import {FlimmoMetaService} from '../../services/flimmo-meta.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit, OnDestroy {
  subs = new SubSink();
  isHandset = false;
  errorCode = 0;
  hasHistory = false;

  constructor(public store: ErrorStoreService, private breakpointObserver: BreakpointObserver, private flimmoMetaService: FlimmoMetaService,
              private location: Location, private router: Router, private routerEvents: RouterEventsService) {
  }

  ngOnInit(): void {
    this.flimmoMetaService.addNoIndex();
    this.subs.sink = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
      .pipe(
        map(result => result.matches),
        shareReplay()
      ).subscribe(value => {
        this.isHandset = value;
      });
    this.subs.sink = this.store.errors$.subscribe(value => {
      this.errorCode = value?.code;
    });
    this.subs.sink = this.routerEvents.previousRoutePath.subscribe(value => {
      if (value) {
        this.location.replaceState(value);
      }
    });
    this.subs.sink = this.routerEvents.previousPreviousRoutePath.subscribe(value => {
      this.hasHistory = !!value;
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.flimmoMetaService.removeNoIndex();
  }

  getErrorMessage(): string {
    if (this.errorCode >= 400 && this.errorCode < 500) {
      return 'error.404';
    }
    if (this.errorCode >= 500 || this.errorCode < 400) {
      return 'error.internal';
    }
    return 'error.notfound';
  }

  back(): void {
    history.back();
  }
}
