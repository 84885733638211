import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-right-drawer',
  templateUrl: './right-drawer.component.html',
  styleUrls: ['./right-drawer.component.scss']
})
export class RightDrawerComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }

}
