import { Component, Inject, isDevMode, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import translations from '../assets/language/de/translations.json';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { DOCUMENT, isPlatformBrowser, isPlatformServer, ViewportScroller } from '@angular/common';
import { filter, map, shareReplay } from 'rxjs/operators';
import { RouterEventsService } from './core/services/router-events.service';
import * as marked from 'marked';
import { SubSink } from 'subsink';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

export const AppConfig = {
  isHandset: false
};

@Component({
  selector: 'app-root',
  template: `
    <app-layout>
      <router-outlet></router-outlet>
    </app-layout>`

})

export class AppComponent implements OnInit {
  subs = new SubSink();

  title = 'flimmo-app';
  lastUrls = [];
  platformId;

  // tslint:disable-next-line:ban-types
  constructor(private translate: TranslateService, @Inject(PLATFORM_ID) platformId: Object, router: Router, viewportScroller: ViewportScroller, private routerEventsService: RouterEventsService, private breakpointObserver: BreakpointObserver) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('de');
    translate.setTranslation('de', translations);
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('de');

    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.lastUrls.push(event.url);
        if (this.lastUrls.length > 2) {
          this.lastUrls.shift();
        }
      });

    router.events.pipe(
      filter((e): e is Scroll => e instanceof Scroll)
    ).subscribe((e: any) => {
      if (this.isDynamicPageContent(e.routerEvent.url)) {
        /**
         * Dynamic content pages ( like markdown, or configurable layout pages )
         * needs some time to initialize view, this delay makes sure the page has time to load
         * Potential optimization via fine grain states: wait for page content to be ready individually ( markdown - ready, api-config ready, etc.. )
         */
        setTimeout(() => {
          this.handleScrollPositionRestoration(e, viewportScroller);
        }, 300);
      } else {
        this.handleScrollPositionRestoration(e, viewportScroller);
      }
    });
    this.platformId = platformId;

    if (isPlatformServer(platformId)) {
      global['marked'] = marked.marked;
    }
  }

  private handleScrollPositionRestoration(e: any, viewportScroller: ViewportScroller): void {
    if (e.position) {
      // backward navigation
      viewportScroller.scrollToPosition(e.position);
    } else if (e.anchor) {
      // anchor navigation
      viewportScroller.scrollToAnchor(e.anchor);
    } else {
      // forward navigation
      // viewportScroller.scrollToPosition([0, 0]);

      // on changing filter parameters the page should stay at the filter position
      // if (this.isSearchURL(e.routerEvent.url) && this.isSearchURL(this.lastUrls[this.lastUrls.length - 1])) {
      //   viewportScroller.scrollToPosition([400, 400]);
      // }
    }
  }

  isSearchURL(url): boolean {
    return url.startsWith('/?');
  }

  isDynamicPageContent(url): boolean {
    return url.startsWith('/redtext/') || url.startsWith('/special/') || url.startsWith('/besprechung/');
  }

  ngOnInit(): void {
    /**
     * Platformbrowser: if the platform is not browser localstorage is not available ( for prerender)
     */

    this.subs.sink = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
      .pipe(
        map(result => result.matches),
        shareReplay()
      ).subscribe(value => {
        AppConfig.isHandset = value;
      });
  }
}
