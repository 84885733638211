import {Injectable} from '@angular/core';
import {Frontpage2Rows, FrontpageService, Swimlane} from '../../../generated-api';
import {FrontpageConfigStoreService} from '../stores/frontpage-config-store.service';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {STREAM_BOXES, TV_BOXES} from '../../../core/helpers/constants/tv-overview';
import {ChannelsScrollerModel} from '../helpers/channels-scroller.model';

@Injectable({
  providedIn: 'root'
})
export class FrontpageConfigService {

  constructor(private frontpageConfigService: FrontpageService,
              private store: FrontpageConfigStoreService,
              private translateService: TranslateService) {
  }

  getFrontpageConfig(previewId): void {
    if (previewId) { // preview
      this.store.loading = true;
      this.frontpageConfigService.getFrontpage2Preview(previewId, 'body').pipe(catchError(err => {
        this.store.loading = false;
        this.store.searchError = this.translateService.instant('error.internal');
        this.store.fpConfig = null;
        return of(null);
      })).subscribe(value => {
        if (value) {
          this.store.loading = false;
          this.store.searchError = null;
          this.store.fpConfig = value;
        }
      });
    } else {
      this.store.loading = true;
      this.frontpageConfigService.getFrontpage2('body').pipe(catchError(err => {
        this.store.loading = false;
        this.store.searchError = this.translateService.instant('error.internal');
        this.store.fpConfig = null;
        return of(null);
      })).subscribe(value => {
        if (value) {
          value.rows = value.rows.filter(row => (row.rowType == 'swimlane' && row !== null && row !== undefined && row.data != null && row.data != undefined && (row.data as Swimlane)?.items.length > 0 ) || row.rowType != 'swimlane');
          this.store.loading = false;
          this.store.searchError = null;
          this.store.fpConfig = value;
        }
      });
    }
  }

  getTvBoxList(): ChannelsScrollerModel[] {
    return TV_BOXES;
  }

  getStreamBoxList(): ChannelsScrollerModel[] {
    return STREAM_BOXES;
  }
}
