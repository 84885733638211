import { Component, HostBinding, HostListener, Input, OnInit } from '@angular/core';
import { CustomSelectComponent } from '../custom-select.component';
import { CustomDropdownService } from '../custom-dropdown/custom-dropdown.service';
import { Highlightable } from '@angular/cdk/a11y';

@Component({
  selector: 'app-custom-select-option',
  template: '<mat-checkbox class="cb" [disableRipple]="true" color="primary" [checked]="selected">{{value}}</mat-checkbox>',
  styleUrls: ['./custom-select-option.component.scss']
})
export class CustomSelectOptionComponent implements OnInit, Highlightable {
  @Input()
  public key: string;

  @Input()
  public value: string;

  @Input()
  public group: string;

  @HostBinding('class.selected')
  public get selected(): boolean {

    return this.select.selectedOption && this.select.selectedOption.length > 0 && this.select.selectedOption.findIndex(value => {
      return value === this;
    }) > -1;
  }

  private select: CustomSelectComponent;

  constructor(private dropdownService: CustomDropdownService) {
    this.select = this.dropdownService.getSelect();
  }

  @HostListener('click', ['$event'])
  public onClick(event: UIEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.select.selectOption(this);
  }

  ngOnInit(): void {
  }

  @HostBinding('class.active')
  public active = false;

  public getLabel(): string {
    return this.value;
  }

  public setActiveStyles(): void {
    this.active = true;
  }

  public setInactiveStyles(): void {
    this.active = false;
  }
}
