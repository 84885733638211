import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Router, RoutesRecognized} from '@angular/router';
import {filter, pairwise} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouterEventsService {

  // save the previous route
  public previousRoutePath = new BehaviorSubject<string | null>(null);
  public previousPreviousRoutePath = new BehaviorSubject<string | null>(null);

  constructor(
    private router: Router,
  ) {

    this.previousRoutePath.next(null);
    this.previousPreviousRoutePath.next(null);

    this.router.events.pipe(
      filter(e => e instanceof RoutesRecognized),
      pairwise(),
    )
      .subscribe((event: any[]) => {
        this.previousPreviousRoutePath.next(this.previousRoutePath.getValue());
        this.previousRoutePath.next(event[0].urlAfterRedirects);
      });

  }
}
