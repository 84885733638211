import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { SubSink } from 'subsink';
import { RedtextChips } from '../../../../core/helpers/models/redtext-chips.model';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { filter, first, map, shareReplay } from 'rxjs/operators';
import { FlimmoMetaService } from '../../../../core/services/flimmo-meta.service';
import { SearchStoreService } from '../../stores/search-store.service';
import { FrontpageConfigStoreService } from '../../stores/frontpage-config-store.service';
import { TranslateService } from '@ngx-translate/core';
import { FrontpageConfigService } from '../../services/frontpage-config.service';
import { PreviewItem } from '../../../../generated-api';
import * as Constants from '../../../../core/helpers/constants/constants';
import { SearchState } from '../../helpers/search-state.enum';
import { ChannelsScrollerModel } from '../../helpers/channels-scroller.model';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformServer } from '@angular/common';

export type FrontpageComponentTypes =
  'lane'
  | 'tvchannelslane'
  | 'streamerslane'
  | 'ratgeberchips'
  | 'biglane'
  | 'special'
  | 'feature';

@Component({
  selector: 'app-frontpage-page',
  templateUrl: './frontpage-page.component.html',
  styleUrls: ['./frontpage-page.component.scss']
})

export class FrontpagePageComponent implements OnInit, OnDestroy {
  eSearchState = SearchState;
  searchState = SearchState.Inactive;
  subs = new SubSink();
  chips: RedtextChips[];
  tvChannels: ChannelsScrollerModel[];
  streamChannels: ChannelsScrollerModel[];

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  isXs$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.XSmall])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  redYellowSearch = false;
  subidxHelper = -1;

  private specialImage: string;

  currentShowsFilterVisible = false;
  private queryParams: any;

  constructor(private flimmoMetaService: FlimmoMetaService,
    public store: SearchStoreService,
    public fpConfigStore: FrontpageConfigStoreService,
    private translateService: TranslateService,
    private frontpageConfigService: FrontpageConfigService,
    private breakpointObserver: BreakpointObserver,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {

  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      this.flimmoMetaService.setFlimmoTitle(this.translateService.instant('frontpage.seo.title'));
      this.flimmoMetaService.setDescription(this.translateService.instant('frontpage.seo.description'));
      this.flimmoMetaService.setOG('https://www.flimmo.de', 'https://www.flimmo.de/assets/media/hero_ueberflimmo.png');

      this.subs.sink = this.fpConfigStore.fpConfig$.pipe(
        first(x => !!x), // avoiding nullables
      ).subscribe(config => {
        this.flimmoMetaService.setOG('https://www.flimmo.de', config.hero?.[0].imagelow);
      })
    }

    if (this.store.searchResults && this.store.searchResults.length > 0) {
      this.searchState = SearchState.HasResults;
    }

    this.subs.sink = this.store.searchResults$.subscribe((searchResults: PreviewItem[]) => {
      this.searchState = searchResults
        && searchResults.length > 0 ? SearchState.HasResults : searchResults === null
        ? SearchState.Inactive : SearchState.HasNoResults;
    });

    this.subs.sink = this.store.loading$.pipe().subscribe((loading: boolean) => {
      this.searchState = loading ? SearchState.Searching : SearchState.Inactive;
    });

    this.subs.sink = this.store.searchParams$.subscribe(value => {
      this.currentShowsFilterVisible = !(value?.src?.length > 0);
      this.redYellowSearch = value && value.alter
        && value.alter.length > 0
        && value.rating
        && value.rating.length > 0
        && value.rating.findIndex(val => {
          return val === '2' || val === '1';
        }) > -1;

      if (value && value.q && value.q.length === 0 && value.rating && value.rating.length === 0 && value.alter && value.alter.length === 0 && value.sources && value.sources.length === 0) {
        this.searchState = SearchState.Inactive;
      }

    });
    this.activatedRoute.params.subscribe(params => {
      this.frontpageConfigService.getFrontpageConfig(params['id']);
      this.tvChannels = this.frontpageConfigService.getTvBoxList();
      this.streamChannels = this.frontpageConfigService.getStreamBoxList();
    })

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
