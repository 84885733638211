import {ChannelsScrollerModel} from '../../../modules/frontpage/helpers/channels-scroller.model';

/**
 * Frontpage TV Box for filterlinks
 */

export const TV_BOXES: ChannelsScrollerModel[] = [
  {assetLink: 'assets/media/btn_kika.svg', channelId: 'KKA', assetLinkAlpha: 'assets/media/kika.svg'},
  {assetLink: 'assets/media/btn_superrtl.svg', channelId: 'RTLS', assetLinkAlpha: 'assets/media/superrtl.svg'},
  {assetLink: 'assets/media/btn_disneychannel.svg', channelId: 'Disney Channel Free TV', assetLinkAlpha: 'assets/media/disneychannel.svg'},
  {assetLink: 'assets/media/btn_nick.svg', channelId: 'NICK', assetLinkAlpha: 'assets/media/nick.svg'},
  {assetLink: 'assets/media/btn_rtl.svg', channelId: 'RTL', assetLinkAlpha: 'assets/media/rtl.svg'},
  {assetLink: 'assets/media/btn_rtl2.svg', channelId: 'RTL2', assetLinkAlpha: 'assets/media/rtl2.svg'},
  {assetLink: 'assets/media/btn_prosieben.svg', channelId: 'PRO7', assetLinkAlpha: 'assets/media/pro7.svg'},
  {assetLink: 'assets/media/btn_sat1.svg', channelId: 'SAT1', assetLinkAlpha: 'assets/media/sat1.svg'},
  {assetLink: 'assets/media/btn_kabel1.svg', channelId: 'K1', assetLinkAlpha: 'assets/media/kabel1.svg'},
  {assetLink: 'assets/media/btn_ard.svg', channelId: 'ARD', assetLinkAlpha: 'assets/media/ard.svg'},
  {assetLink: 'assets/media/btn_zdf.svg', channelId: 'ZDF', assetLinkAlpha: 'assets/media/zdf.svg'},
];
/**
 * Frontpage Stream Box for filterlinks
 */
export const STREAM_BOXES: ChannelsScrollerModel[] = [
  {assetLink: 'assets/media/btn_disney+.svg', channelId: 'disney_plus', assetLinkAlpha: 'assets/media/disney+.svg'},
  {assetLink: 'assets/media/btn_joyn.svg', channelId: 'Joyn', assetLinkAlpha: 'assets/media/joyn.svg'},
  {assetLink: 'assets/media/btn_netflix.svg', channelId: 'netflix', assetLinkAlpha: 'assets/media/netflix.svg'},
  {assetLink: 'assets/media/btn_primevideo.svg', channelId: 'amazon', assetLinkAlpha: 'assets/media/primevideo.svg'},
  {assetLink: 'assets/media/btn_rtl+.svg', channelId: 'RTLPLUS', assetLinkAlpha: 'assets/media/tvnow.svg'},
  {assetLink: 'assets/media/btn_zdfmediathek.svg', channelId: 'ZDF Mediathek', assetLinkAlpha: 'assets/media/zdfmediathek.svg'},
  {assetLink: 'assets/media/btn_ardmediathek.svg', channelId: 'ARD Mediathek', assetLinkAlpha: 'assets/media/ardmediathek.svg'},
];