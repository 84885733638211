import {Component, Input, isDevMode, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {formatDate} from '../../../../shared/functions/utils';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  version = environment.appVersion;
  @Input() isHandset;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  toFrontpage(): void {
      this.router.navigateByUrl('/');
  }
}
