import {Component, ViewChild} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {formatDate} from '../../shared/functions/utils';
import {Router} from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {

  @ViewChild('drawer') leftDrawer;
  @ViewChild('drawer2') rightDrawer;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe('(max-width:650px)')
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  leftSideNavVisible = false;
  rightSideNavVisible = false;
  showFooter = true;

  constructor(private breakpointObserver: BreakpointObserver, private router: Router) {
  }

  toggleDrawer(): void {
    this.leftDrawer.toggle();
    this.rightDrawer.toggle();
  }

  isProd(): boolean {
    return environment.production;
  }

  toEditor(): void {
    this.router.navigate(['editor']);
  }

}
