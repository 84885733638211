import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {ChipsScrollableComponent} from './components/chips-scrollable/chips-scrollable.component';
import {RouterModule} from '@angular/router';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {TrafficLightsComponent} from './components/traffic-lights/traffic-lights.component';
import {AgeCategoryBadgeComponent} from './components/age-category-badge/age-category-badge.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {SwimlanesComponent} from './components/swimlanes/swimlanes.component';
import {SafePipeModule} from 'safe-pipe';
import {GlossaryDialogComponent} from './components/glossary-dialog/glossary-dialog.component';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MediaDialogComponent} from './components/media-dialog/media-dialog.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {LegendDialogComponent} from './components/legend-dialog/legend-dialog.component';
import {NoteDialogComponent} from './components/note-dialog/note-dialog.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { CustomSelectOptionComponent } from './components/custom-select/custom-select-option/custom-select-option.component';
import { CustomDropdownComponent } from './components/custom-select/custom-dropdown/custom-dropdown.component';
import {PortalModule} from '@angular/cdk/portal';
import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import { CustomSelectDialogComponent } from './components/custom-select/custom-select-dialog/custom-select-dialog.component';
import { ScrollTrackerDirective } from './directives/scroll-tracker/scroll-tracker.directive';
import {TruncateModule} from '@yellowspot/ng-truncate';
import {MarkdownModule} from 'ngx-markdown';


@NgModule({
    declarations: [ChipsScrollableComponent,
        TrafficLightsComponent,
        AgeCategoryBadgeComponent,
        SwimlanesComponent,
        GlossaryDialogComponent,
        MediaDialogComponent,
        LegendDialogComponent,
        NoteDialogComponent,
        CustomSelectComponent,
        CustomSelectOptionComponent,
        CustomDropdownComponent,
        CustomSelectDialogComponent,
        NoteDialogComponent,
        ScrollTrackerDirective],
    imports: [
        CommonModule,
        FlexLayoutModule,
        TranslateModule,
        MatProgressSpinnerModule,
        RouterModule,
        MatChipsModule,
        MatButtonModule,
        MatIconModule,
        SafePipeModule,
        MatIconModule,
        MatDialogModule,
        OverlayModule,
        MatCheckboxModule,
        PortalModule,
        FormsModule,
        ReactiveFormsModule,
        TruncateModule,
        MarkdownModule
    ],
    exports: [
        CommonModule,
        TranslateModule,
        FlexLayoutModule,
        MatProgressSpinnerModule,
        MatButtonModule,
        MatIconModule,
        SafePipeModule,
        ChipsScrollableComponent,
        TrafficLightsComponent,
        AgeCategoryBadgeComponent,
        SwimlanesComponent,
        GlossaryDialogComponent,
        MediaDialogComponent,
        NoteDialogComponent,
        ScrollTrackerDirective,
        NoteDialogComponent,
        CustomSelectComponent,
        CustomSelectOptionComponent,
        FormsModule,
        ReactiveFormsModule,
    ]
})
export class SharedModule {
}
