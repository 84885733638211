import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Frontpage, Frontpage2 } from '../../../generated-api';

@Injectable({
  providedIn: 'root'
})
export class FrontpageConfigStoreService {
  // tslint:disable-next-line:variable-name
  private readonly _fpConfig = new BehaviorSubject<Frontpage2>(null);
  readonly fpConfig$ = this._fpConfig.asObservable();

  get fpConfig(): Frontpage2 {
    return this._fpConfig.getValue();
  }

  set fpConfig(val: Frontpage2) {
    this._fpConfig.next(val);
  }


  private readonly _loading = new BehaviorSubject<boolean>(false);
  readonly loading$ = this._loading.asObservable();

  get loading(): boolean {
    return this._loading.getValue();
  }

  set loading(val: boolean) {
    this._loading.next(val);
  }

  private readonly _searchError = new BehaviorSubject<string>(null);
  readonly searchError$ = this._searchError.asObservable();

  get searchError(): string {
    return this._searchError.getValue();
  }

  set searchError(val: string) {
    this._searchError.next(val);
  }
  constructor() { }
}
