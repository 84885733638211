import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import titleReplacements from '../../../assets/config/title-replacements.json';

@Injectable({
  providedIn: 'root'
})
export class FlimmoMetaService {

  constructor(private title: Title, private meta: Meta) {
  }

  setFlimmoTitle(title: string): void {
    this.title.setTitle(title);
    this.meta.updateTag({
      property: 'og:title', content: title
    });
  }

  prettifyUrl(title: string): string {
    if (!title) title = '';
    let prettyString = title;
    titleReplacements.forEach(val => {
      prettyString = prettyString.split(val.from).join(val.to);
    });
    return prettyString;
  }

  setDescription(desc: string): void {
    this.meta.updateTag({
      name: 'description',
      content: desc
    });
    this.meta.updateTag({
      property: 'og:description',
      content: desc
    });
  }

  setOG(permaLink: string, imgUrl: string): void {
    this.meta.updateTag({
      property: 'og:site_name', content: 'FLIMMO'
    });
    this.meta.updateTag({
      property: 'og:locale', content: 'de_DE'
    });
    this.meta.updateTag({
      property: 'og:url', content: permaLink
    });
    if (imgUrl) {
      this.meta.updateTag({
        property: 'og:image', content: imgUrl
      });
    }
  }



  addNoIndex(): void {
    this.meta.addTag({name: 'robots', content: 'noindex'});
  }
  removeNoIndex(): void {
    this.meta.removeTag("name='robots'");
  }
}
