export const environment = {
  production: true,
  apiUrl: require('../../scripts/environments.js').apiUrlStage,
  appVersion: require('../../package.json').version + '-stg',
  firebaseConfig: {

  }
};

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
