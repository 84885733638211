import { Injectable } from '@angular/core';
import { catchError, shareReplay } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DefaultService } from '../../../generated-api';
import { SearchStoreService } from '../stores/search-store.service';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class SearchService {
  static LIMIT = 40;
  constructor(
    private store: SearchStoreService,
    private defaultService: DefaultService,
    private http: HttpClient,
    private translateService: TranslateService) {
  }


  search(q?: string,
    rating?: number[],
    altersfreigabe?: number[],
    sources?: string[],
    genre?: string[],
    offset?: number,
    limit?: number,
    cb?): void {

    this.store.searchParams = {
      q,
      rating,
      alter: altersfreigabe,
      src: sources,
      genre: genre,
      offset,
      limit
    };

    if (sources == null && SearchStoreService.isCurrentShowsChecked) {
      sources = ['any'];
    }

    this.store.offset = offset;
    this.store.loading = true;
    this.defaultService.search(rating, null, altersfreigabe, 1, q, offset, limit, sources, genre, undefined)
      .pipe(shareReplay(1), catchError(err => {
        this.store.loading = false;
        this.store.searchError = this.translateService.instant('error.internal');
        this.store.searchResults = null;
        this.store.offset = null;
        return of(err);
      })).subscribe(value => {
        if (value.result) {
          this.store.loading = false;
          this.store.searchResults = !this.store.searchResults ? value.result : this.store.searchResults.concat(value.result);
          this.store.rowCount = value.rowcount;
          this.store.searchError = null;
          if (cb) {
            cb(value.result);
          }
        }
      });
  }
}
